<template>
  <section class="BeneficiarioMain empresas__inner container mt24 mb24">
    <v-app style="flex: 1 1 auto!important;">
      <v-main style="background:transparent">
        <header class="empresas__header">
          <h2 class="empresas_home__heading heading">Beneficiario</h2>
          <beneficiario-codigo
            v-if="!token"
            @session="_handleTempSesion"
            @msg="_notify"
            @httpError="_resFail"
          />
        </header>
        <beneficiario-familia-listado
          v-if="token"
          :token="token"
          :nombre="nombre"
        />
      </v-main>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="4500"
        absolute
        right
        shaped
      >
        <v-btn
          text
          @click="snackbar.show = false"
          icon
          class="dark-icon"
        >
          <v-icon v-text="'close'" />
        </v-btn>
        {{ snackbar.text }}
      </v-snackbar>
    </v-app>
  </section>
</template>
<script>
import BeneficiarioCodigo from "@/components/beneficiario/BeneficiarioCodigo.vue";
import BeneficiarioFamiliaListado from "@/components/beneficiario/BeneficiarioFamiliaListado.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  name: "BeneficiarioMain",
  components: { BeneficiarioCodigo, BeneficiarioFamiliaListado, DeleteDialog },
  data() {
    return {
      showCodigo: true,
      showClasses: "",
      snackbar: {
        show: false,
        text: "",
      },
      showAll: false,
      isLogedIn: false,
      token: null,
      nombre: null,
    };
  },
  methods: {
    _notify(a) {
      this.snackbar.text = a;
      this.snackbar.show = true;
    },
    _resFail(a) {
      if (a.status === 400) {
        this._notify(a.body.error);
      } else if (a.body.error) {
        this._notify(a.body.error);
      } else {
        this._notify(this.$noInternet);
      }
    },
    _handleTempSesion(a) {
      this.token = a.token;
      this.nombre = a.nombre;
    },
  },
  created() {
    this.$session.destroy();
  },
  beforeDestroy() {
    this.$session.destroy();
  },
};
</script>