<template>
  <div class="BeneficiarioCodigo empresas_home__text">
    Si perteneces a algún programa, aquí podrás registrar a tus beneficiarios ingresando el <span style="background: #ecb731">&nbsp;código de 6 dígitos&nbsp;</span> que te proveyeron en tu lugar de trabajo.
    <br><br>
    <v-text-field
      label="Código de acceso"
      v-mask="'NNNNNN'"
      maxlength="6"
      filled
      v-model="codigo"
      @keyup.native.enter="_getBeneficiario"
    />
    <v-btn
      class="primary"
      depressed
      @click="_getBeneficiario"
    >
      Entrar
      <v-icon>chevron_right</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "BeneficiarioCodigo",
  data() {
    return {
      codigo: null,
      cargando: false,
    };
  },
  methods: {
    async _getBeneficiario() {
      try {
        this.cargando = true;
        const { status, body } = await this.$http.post("beneficiario/codigo", {
          codigo: this.codigo,
        });
        if (status === 200 && "token" in body) {
          this.$emit("msg", body.msg);
          this.$emit("session", body);
          this.$session.start();
          delete this.$headers.headers.Token;
          this.$headers.headers.Token = body.token;
          this.$session.set("nombre", body.nombre);
          this.$session.set("token", body.token);
          this.$session.set("tipo", "beneficiario");
        } else {
          this.$emit("msg", "Código no encontrado");
        }
      } catch (err) {
        this.$emit("httpError", err);
      }
    },
  },
  watch: {
    codigo(a) {
      if (a && a.length >= 6) {
        this._getBeneficiario();
      }
    },
  },
};
</script>