<template>
  <div class="BeneficiarioFamiliaListado">
    <div class="empresas_home__text">
      {{nombre}}, esta es la familia que has registrado.
    </div>
    <v-card
      flat
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="beneficiarios"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.codigo="{ item }">
          <span
            v-if="item.familia === 'si'"
            v-text="item.codigo"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.titular"
            icon
            @click="idBenToDelete = item.id"
          >
            <v-icon v-text="'delete'" />
          </v-btn>
          <span v-else>Titular</span>
        </template>
      </v-data-table>
    </v-card><br><br>
    <br><br>
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Registra tu familia directa</h2>
      <div class="empresas_home__text">
        Para poder registrar a un beneficiario es importante que llenes
        correctamente todos los campos marcados con *.<br /><span style="background: #ecb731">Se mostrará una ventana si detectamos que el beneficiario ya se
          encuentra registrado en nuestra base de datos, te pedimos evites la
          generación de pacientes duplicados para poder ofrecer una mejor
          experiencia a los beneficiarios</span>
      </div>
    </header>
    <v-card
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <v-text-field
            v-model="paciente.nombre_pac"
            :error-messages="errors.nombre_pac"
            label="Nombre*"
            filled
            @blur="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="paciente.ape_pat_pac"
            :error-messages="errors.ape_pat_pac"
            label="Apellido paterno*"
            filled
            @blur="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="paciente.ape_mat_pac"
            :error-messages="errors.ape_mat_pac"
            label="Apellido materno*"
            filled
            @blur="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            label="Sexo*"
            filled
            :items="[
              { text: 'Femenino', value: 'femenino' },
              { text: 'Masculino', value: 'masculino' },
              {
                text: 'Prefiero no especificar',
                value: 'prefiero no especificar',
              },
            ]"
            v-model="paciente.sexo_pac"
            :error-messages="errors.sexo_pac"
            @change="_checkIfExists"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="paciente.fecha_nac_pac"
            label="Fecha de nacimiento*"
            placeholder="YYYY-MM-DD"
            v-mask="'####-##-##'"
            hint="Año-mes-día"
            prepend-inner-icon="today"
            filled
            :error-messages="errors.fecha_nac_pac"
            @blur="_checkIfExists"
          />
        </v-col>
      </v-row>
      <v-subheader v-text="'Informacion demográfica'" />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <AutocompleteDataEstados
            label="Estado*"
            isSelect
            v-model="paciente.id_edo_pac"
            :error="errors.id_edo_pac"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <AutocompleteDataMunicipios
            label="Municipio*"
            isSelect
            :estado="paciente.id_edo_pac"
            v-model="paciente.id_mpio_pac"
            :error="errors.id_mpio_pac"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <v-text-field
            v-model="paciente.calle_pac"
            :error-messages="errors.calle_pac"
            label="Calle*"
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="2"
        >
          <v-text-field
            v-model="paciente.num_ext_pac"
            :error-messages="errors.num_ext_pac"
            label="# ext*"
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="2"
        >
          <v-text-field
            v-model="paciente.num_int_pac"
            :error-messages="errors.num_int_pac"
            label="# int"
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="12"
          md="4"
        >
          <v-text-field
            v-model="paciente.colonia_pac"
            :error-messages="errors.colonia_pac"
            label="Colonia*"
            filled
          />
        </v-col>
      </v-row>
      <v-card-actions class="card_no_padding">
        <v-spacer />
        <v-btn
          text
          @click="_limpiarCampos('paciente')"
          v-text="'Cancelar'"
        />
        <v-btn
          class="primary"
          depressed
          v-text="'Guardar familiar'"
          @click="_savePac"
        />
      </v-card-actions>
    </v-card>
    <v-dialog
      id="existenPacientesDuplicados"
      v-model="showDialogExiste"
      max-width="500"
      persistent
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-html="'Hemos encontrado estos pacientes con los mismos datos'" />
        <v-card-subtitle v-html="
            '¿Alguno de los resultados es quien intentas registrar como tu familiar?'
          " />
        <v-list-item-group>
          <div
            v-for="(item, i) in pacientes"
            :key="i"
          >
            <v-divider />
            <v-list-item @click="_saveSelectedPac(item)">
              <v-list-item-content>
                <v-list-item-title v-text="
                    `${item.nombre_pac} ${item.ape_pat_pac} ${item.ape_mat_pac}`
                  " />
                <v-list-item-subtitle v-text="
                    `${item.fecha_nac_pac}, Domicilio: ${item.calle_pac.slice(
                      0,
                      8
                    )}...`
                  " />
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-text="'keyboard_arrow_right'" />
              </v-list-item-icon>
            </v-list-item>
          </div>
        </v-list-item-group>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogExiste = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      errorMessage="¿Está seguro que desea eliminar a este familiar como beneficiario?"
      errorMessageDetail="Una vez eliminado perderá todos los beneficios y para recuperarlos deberá darlo de alta nuevamente"
      :showDialog="showDialogDelete"
      @closed="idBenToDelete=false;showDialogDelete=false"
      @confirmDelete="_deleteBeneficiario"
    />
    <br><br>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import beneficiariosForm from "@/mixins/beneficiariosForm.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  //kajsndl que ocura
  name: "BeneficiarioFamiliaListado",
  props: {
    token: String,
    nombre: String,
  },
  mixins: [forms, beneficiariosForm],
  components: {
    DeleteDialog,
  },
  data() {
    return {
      search: "",
      showDialogDelete: false,
      headers: [
        { text: "Beneficiario", value: "beneficiario" },
        { text: "Desde", value: "desde" },
        { text: "", value: "actions" },
      ],
      beneficiarios: [],
      idBenToDelete: null,
    };
  },
  methods: {
    _getBeneficiarios() {
      this._getThings("beneficiario", "beneficiarios");
    },
    _deleteBeneficiario() {
      this._deleteAll(
        "beneficiarios/" + this.idBenToDelete,
        "msg",
        "_handleResponseDelete"
      );
      //asd
    },
    _handleResponseDelete(res) {
      this.$emit("msg", res.msg);
      this._getBeneficiarios();
      this.showDialogDelete = false;
      this.idBenToDelete = false;
    },
    async _searchPac(data) {
      try {
        const { status, body } = await this.$http.post(
          "pacientes/buscar",
          { paciente: data },
          this.$headers
        );
        this.pacientes =
          status === 200 && "pacientes" in body ? body.pacientes : [];
      } catch (err) {
        this.pacientes = [];
      }
    },
    _savePac() {
      if (this._validateAll("paciente") && this._validateFechaNac()) {
        this.cargando = true;
        const data = {
          paciente: this.paciente,
          existe: "no",
        };
        this._saveAll("beneficiario", data, "id", "_handleBeneficiarioRes");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _saveSelectedPac(pac) {
      this.cargando = true;
      const data = {
        paciente: pac.id,
        existe: "si",
      };
      this._saveAll("beneficiario", data, "id", "_handleBeneficiarioRes");
    },
    _handleBeneficiarioRes(res) {
      window.scrollTo(0, 0);
      this.pacientes = [];
      this.showDialogExiste = false;
      this._limpiarCampos("paciente");
      this.$emit("msg", res.msg);
      this._getBeneficiarios();
      //aqui preguntamos si quiere seguir guardando o no
    },
  },
  created() {
    this._getBeneficiarios();
  },
  watch: {
    idBenToDelete(a) {
      if (a) this.showDialogDelete = true;
    },
  },
};
</script>